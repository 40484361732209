
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300;400;500&display=swap");

* {
  padding: 0;
  margin: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.ffot {
  position: sticky;
  top: 100%;
}

a {
  text-decoration: none !important;
  list-style: none !important;
}
body {
  padding-right: 0 !important;
  overflow: auto !important;
}
.modal {
  padding-left: 0 !important;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
input:hover,
button:hover,
label:focus,
select:focus,
select:hover {
  outline: 0px !important;
  /* -webkit-appearance: none !important; */
  /* box-shadow: none !important; */
}
button:focus,
button:active,
.btn:active,
.btn.active {
  outline: 0px !important;
  box-shadow: none !important;
}

.form-group label {
  font-size: 15px !important;
}

p {
  margin-bottom: 0 !important;
  font-size: 14px;
}
hr {
  border-top: none !important;
  margin: 1.5px 0 !important;
}

.bt_bg {
  background-color: #274160 !important;
  outline: none;
  border: none;
  border-radius: 2rem !important;
  float: right !important;
  margin-top: 15px !important;
}
.page_contnet {
  margin-top: 6rem;
}

.modal-title {
  text-align: center !important;
}

.form-select:hover {
  outline: 0px !important;
  -webkit-appearance: none;
}
.lab {
  font-size: 14px !important;
}

.form-select {
  outline: 0px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border: none !important;
}

.form-control {
  border: 1px solid #ccc !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.btn-primary {
  background-color: #224530 !important;
  border: 0 !important;
}

.modal-backdrop.show {
  opacity: 0.6 !important;
}

.modal-header {
  padding: 0.1rem 1rem !important;
  background-color: #d56928 !important;
  color: #fff !important;
  box-shadow: 0px 2px 2px #898383;
  border-bottom: none !important;
}
.aa_logo img {
  width: 34px;
  position: absolute;
  left: 6px;
  top: 6px;
}
/* .css-1w5m2wr-MuiDataGrid-virtualScroller{
  height: 280px !important;
} */
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 0rem !important;
  }
}
