.table-bordered > :not(caption) > * {
  text-align: center !important;
  font-size: 11px !important;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.8rem !important;
}
.table > tbody {
  vertical-align: middle !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.table th {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #444 !important;
}
.table td {
  font-size: 12px !important;
  text-align: center !important;
}
