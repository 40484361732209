/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #d9dde6 !important;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e6eaf3 !important;
  border-radius: 4px;
}
::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px #c7c9cf !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(195, 192, 192);
  border-radius: 4px;
}

button.btn_submit:nth-child(1) {
  background-color: #d56928 !important;
  border: 0px;
}

.modal.fade .modal-dialog {
  transition: transform 0.8s ease-out !important;
}

.MuiTooltip-tooltip,
.MuiTooltip-arrow::before {
  background-color: #224530 !important;
}

.css-ja5taz-MuiTooltip-tooltip {
  font-size: 14px !important;
}
.bounce-vertically-90:nth-child(2) {
  background: #d56928 !important ;
}
.bounce-vertically-90:nth-child(1) {
  background: #2f5888 !important ;
}
.bounce-vertically-90:nth-child(3) {
  background: #224530 !important ;
}
.dropdown-menu[data-bs-popper] {
  margin-top: 0 !important;
}

.main_com {
  min-height: 80vh !important;
}
.css-1uy55vg-MuiSpeedDial-root {
  z-index: 1 !important;
}
